body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header0 {
  background: #2f2f30;
  width: 100%;
  z-index: 1;
  position: relative;
  top: 0;
}
.header0 .home-page {
  padding: 0 24px;
}
.header0-logo {
  background: #2f2f30;
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header0-logo img {
  width: 40%;
  vertical-align: middle;
  display: inline-block;
}
.header0-logo a {
  display: block;
}
.header0-menu {
  float: right;
}
.header0-menu .ant-menu {
  background: #2f2f30;
  line-height: 62px;
  height: 64px;
}
.header0-menu .ant-menu a {
  display: block;
}
.header0-item-block {
  padding: 0 8px;
}
.header0-item-block > * {
  display: inline-block;
}
.header0-item .ant-menu-sub .ant-menu-item,
.header0-item-child .ant-menu-sub .ant-menu-item,
.header0-menu .ant-menu-sub .ant-menu-item,
.header0-item .ant-menu-inline .ant-menu-item,
.header0-item-child .ant-menu-inline .ant-menu-item,
.header0-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header0-item .item-sub-item,
.header0-item-child .item-sub-item,
.header0-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header0-item .item-image,
.header0-item-child .item-image,
.header0-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header0-item .item-title,
.header0-item-child .item-title,
.header0-menu .item-title {
  font-size: 14px;
  color: #fff;
  margin-left: 46px;
}
.header0-item .item-content,
.header0-item-child .item-content,
.header0-menu .item-content {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.75);
  margin-left: 46px;
}
@media screen and (max-width: 767px) {
  .header0-logo {
    z-index: 101;
  }
  .header0.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header0-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-menu li {
    padding: 0 24px;
  }
  .header0-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header0-menu .item-sub-item {
    padding: 8px 0;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-mobile-menu :first-child {
    margin-top: 0;
  }
  .header0 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header0 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header0 .open {
    height: auto;
  }
  .header0 .open .header0-mobile-menu em:nth-child(1) {
    -webkit-transform: translateY(6px) rotate(45deg);
            transform: translateY(6px) rotate(45deg);
  }
  .header0 .open .header0-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header0 .open .header0-mobile-menu em:nth-child(3) {
    -webkit-transform: translateY(-6px) rotate(-45deg);
            transform: translateY(-6px) rotate(-45deg);
  }
  .header0 .open > .header0-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header0-item-block {
    height: 40px;
    line-height: 40px;
  }
}
.banner0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("/static/images/bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #fff;
  width: 550px;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 350px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  position: relative;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  line-height: 40px;
  font-size: 16px;
  height: 40px;
  -webkit-transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .banner0 {
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 90%;
    left: 0;
  }
}
.content0-wrapper {
  height: 746px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0 .block-wrapper .block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  margin-bottom: 48px;
}
.content0-wrapper .content0 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0 .block-wrapper .block .icon {
  width: 100%;
  max-width: 350px;
  height: 220px;
  margin: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.content0-wrapper .content0 .block-wrapper .block > h3 {
  line-height: 32px;
  margin: 10px auto;
}
.content0-wrapper .content0 .block-wrapper .block > p {
  line-height: 26px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    height: 100%;
  }
}
.content1-wrapper {
  min-height: 200px;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-text {
  padding: 0 32px;
  line-height: 2;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper .content1-img {
    padding: 0;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
    line-height: 2;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content3-wrapper .content3 {
  height: 100%;
  overflow: hidden;
}
.content3-wrapper .content3 .title-content {
  text-align: center;
}
.content3-wrapper .content3-block-wrapper {
  height: auto;
  position: relative;
}
.content3-wrapper .content3-block-wrapper .content3-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.content3-wrapper .content3-block-wrapper .content3-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .content3-wrapper .content3-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .content3-wrapper .content3-block-wrapper .content3-block .content3-title {
    font-size: 20px;
  }
  .content3-wrapper .content3-block-wrapper .content3-block.queue-anim-leaving {
    position: relative !important;
  }
}
.footer0-wrapper {
  background-color: #2f2f30;
  height: 50vh;
  overflow: hidden;
}
.footer0-wrapper .footer0 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
@media screen and (max-width: 767px) {
  .footer0-wrapper .footer0 {
    font-size: 12px;
  }
  .footer0-wrapper .footer0.home-page {
    padding: 0;
  }
  .footer0-wrapper .footer0 > div {
    width: 90%;
    margin: auto;
  }
}
#Banner0_0 .banner0-text-wrapper > .k0vpih7a9e-editor_css {
  font-size: 38px;
  color: #00b9ae;
  position: static;
}
#Nav0_0 .ant-menu-item > .header0-item-block > .k0vpklie5rk-editor_css {
  color: #00b9ae;
}
#Nav0_0 .home-page > .k0vpknyyiod-editor_css {
  color: #00b9ae;
}
#Banner0_0 .banner0-text-wrapper > .k0vpk9w9mcm-editor_css {
  color: #00b9ae;
  border-top-color: #00b9ae;
  border-right-color: #00b9ae;
  border-bottom-color: #00b9ae;
  border-left-color: #00b9ae;
}
#Banner0_0 .banner0-text-wrapper > .k0vpj6u0wsu-editor_css {
  font-size: 16px;
  line-height: 5px;
  color: #00b9ae;
}
#Content0_0 div > .ant-row > .k0vpnn4wlwt-editor_css {
  min-height: 400px;
}
#Content0_0 div > .ant-row > .k0vpo32dt1-editor_css {
  min-height: 400px;
}
#Content0_0 div > .ant-row > .k0vpo6odseb-editor_css {
  min-height: 400px;
}
#Nav0_0 .ant-menu-submenu-title > .header0-item-block > .k0vpkekxw3-editor_css {
  color: #00b9ae;
}
#Content0_0 .ant-col > .content0-block-item > .k0vpvhp126-editor_css {
  font-size: 15px;
  line-height: 2;
}
#Content0_0 .ant-col > .content0-block-item > .k0vpv9ch7re-editor_css {
  font-size: 15px;
  line-height: 2;
}
#Content0_0 .ant-col > .content0-block-item > .k0vpv2iipg-editor_css {
  font-size: 15px;
  line-height: 2;
}
#Content0_0 .ant-col > .content0-block-item > .k0vpvwqr759-editor_css {
  font-size: 32px;
}
#Content0_0 .ant-col > .content0-block-item > .k0vpvtw2vg-editor_css {
  font-size: 32px;
}
#Content0_0 .ant-col > .content0-block-item > .k0vpvqtndze-editor_css {
  font-size: 32px;
}
#Content0_0 .ant-col > .content0-block-item > .k0vpxd67uol-editor_css {
  width: 100%;
  height: 300px;
}
#Content0_0 .ant-col > .content0-block-item > .k0vpyne2a6q-editor_css {
  width: 100%;
  height: 300px;
}
#Content0_0 .ant-col > .content0-block-item > .k0vpyv69e6d-editor_css {
  width: 100%;
  height: 300px;
}
#Nav0_0 .ant-menu-item > .header0-item-block > .k0vpkhctp7-editor_css {
  color: #00b9ae;
}
#Nav0_0 .ant-menu-item > .header0-item-block > .k0vpkekxw3-editor_css {
  color: #00b9ae;
}
#Nav0_0 .ant-menu-item > .header0-item-block > .k0vpkekxw3-editor_css:hover {
  color: #0a0a0e;
}
#Nav0_0 .ant-menu-item > .header0-item-block > .k0vpkekxw3-editor_css:active {
  color: #0a0a0e;
}
#Nav0_0 .ant-menu > .ant-menu-item > .k0vq2q8gnv-editor_css:hover {
  color: #0a0a0e;
}
#Nav0_0 .ant-menu > .ant-menu-item > .k0vq2q8gnv-editor_css:active {
  color: #0a0a0e;
}
#Nav0_0 .ant-menu-item > .header0-item-block > .k0vpkjgc2u-editor_css {
  color: #00b9ae;
}
#Nav0_0 .ant-menu-item > .header0-item-block > .k0vpkjgc2u-editor_css:hover {
  color: #0a0a0e;
}
#Nav0_0 .ant-menu-item > .header0-item-block > .k0vpkjgc2u-editor_css:active {
  color: #0a0a0e;
}

